import React, { useRef } from "react";
import { Link } from "react-router-dom";
import MovableButton from "../../components/shareable/MovableButton";

const ProjectItem = ({ project }) => {
  const containerRef = useRef(null);

  return (
    <div ref={containerRef} className="item">
      <Link target={"_blank"} to={`${project.link}`}>
        <div className="image-animate custom-show">
          <div className="wrapper">
            <img
              src={
                process.env.PUBLIC_URL + `/projectImage/${project.image}.jpg`
              }
              alt=""
            />

            <div className="info">
              <div className="category">{project.category}</div>

              <div className="title">
                {project.name} - {project.case}
              </div>
            </div>
          </div>
        </div>

        <MovableButton containerRefs={[containerRef]} name="View" />
      </Link>
    </div>
  );
};

export default ProjectItem;
