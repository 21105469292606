import React from "react";

import "./hero.css";

const Hero = ({ children, title }) => {
  return (
    <section className="hero container-narrow">
      <h1
        className="separate-text animate-element fade-up-line onload"
        data-text={title}
      >
        {title}
      </h1>
      {children}
    </section>
  );
};

export default Hero;
