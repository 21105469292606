import React from "react";
import Button from "../../components/shareable/Button";

// import Clutch from "../../assets/icons/clutch.svg";
// import Stars from "../../assets/icons/stars.svg";

const About = () => {
    return (
        <section className="about-us container-narrow section-space animate-element fade-up">
            <h2>Design, build and maintain products</h2>
            <div className="description">
                <p>
                    Based out of UK, CodeCapital has become one of the best
                    quality Digital Web Development Agency in the country.
                    Our focus has always been to create enjoyable, intuitive,
                    engaging and remarkable experience for
                    people —
                    that's what sets us apart from everyone else.
                </p>
                <p>
                    We are a team of creatives who design identities, custom-build
                    websites and launch digital products that empower teams.
                </p>
            </div>
            <div className="clutch-box">
                {/*<a*/}
                {/*    href="https://clutch.co/profile/handmade#summary"*/}
                {/*    target="_blank"*/}
                {/*    rel="noreferrer"*/}
                {/*    className="clutch-wrapper"*/}
                {/*>*/}
                {/*    /!*<img src={Clutch} className="clutch" alt="" />*!/*/}
                {/*</a>*/}
                <div>
                    {/*<img src={Stars} className="stars" alt="" />*/}
                    <p className="small-text">
                        Rated 5.0/5.0 for web design and <br/>
                        development services
                    </p>
                </div>
            </div>
            <Button type="dark" text="About us" href="about"/>
        </section>
    );
};

export default About;
