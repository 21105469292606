import React, { useRef } from "react";
import MovableButton from "./../../components/shareable/MovableButton";

import { Link } from "react-router-dom";

const titles = [
  "Web and mobile UI UX Design",
  "Web and mobile development",
  "Identity design and branding",
];

const Services = ({ sectionRef }) => {
  const titleRefs = [useRef(null), useRef(null), useRef(null)];

  return (
    <>
      <MovableButton name="More" containerRefs={titleRefs} />

      <section
        ref={sectionRef}
        id="services"
        className="services section-space container-narrow"
      >
        {titles.map((title, index) => (
          <Link to="services" key={index}>
            <div className="item animate-element fade-up">
              <div className="label">
                <p>0{index + 1} SERVICE</p>
              </div>
              <h2 ref={titleRefs[index]}>{title}</h2>
            </div>
          </Link>
        ))}
      </section>
    </>
  );
};

export default Services;
