import React from "react";
import FaqItem from "./FaqItem";

const lists = [
    {
        question: "What separates CodeCapital from other web design agencies?",
        answer:
            "We get this question a lot. There are many competent UI/UX agencies in our region alone and even more globally. It’s hard to be different when the standard is so high. So how do we stand out? First and foremost, it’s the quality of our UX/UI design work and the craft it takes to make it. The digital products and user interfaces we create are easy to use, look great, and represent your brand in the best way possible. Then, it’s the people who make your project a reality. We always assign a multidisciplinary team of best UX/UI designers, developers, and other specialists.",
    },
    {
        question: "What is your key expertise?",
        answer:
            "We specialize in complete product development: from the very first ideation stages, through design, prototyping and testing to development and launch. Our development team can deliver both web and mobile products using most popular technologies.",
    },
    {
        question: "Do you do branding or should I hire a separate branding agency?",
        answer:
            "Yes, we do. We position ourselves as a new kind of design agency focused on companies from many different fields. Not so many agencies can create a visual identity for a concierge services like we did for Belgrades Finest. Our branding capabilities include research, brand and digital strategy, logo design, visual identity, graphic design, motion design, and style guide development, and web design, among other things.",
    },
    {
        question: "How much does it cost to hire you for a project?",
        answer:
            "Our pricing is directly related to the project scope, timeline, deliverables, and team composition. However, all our engagements are fixed‑price contracts. In this case, we’ll give you a detailed proposal after learning as much as possible about your project. Please email us to get a quote.",
    },
    {
        question: "How can I get a proposal for my project?",
        answer:
            "If you're looking for a design or development agency, please send us an email first with a quick summary of your project. We usually reply within 24 hours to schedule an introductory call and ask any immediate questions. During our first call, we will ask a lot of questions about the project goals, audience, budget, timeline, and other essential details. We can sign an NDA if necessary, but we always treat all client conversations as confidential. While many agencies only work by the hour, we try to estimate the project cost as precisely as possible before we start. After the call, we may request various documentation such as a design brief, project requirements, past designs, etc.",
    },
];

const Faq = () => {
    return (
        <section className="faq section-space container-narrow">
            <h2 className="animate-element fade-up">Frequently asked questions</h2>

            <div className="faq-wrapper">
                {lists.map((item, index) => (
                    <FaqItem key={index} item={item}/>
                ))}
            </div>
        </section>
    );
};

export default Faq;
