import React, { useState, useEffect, useRef } from "react";

import "./footer.css";
import SocialIcons from "./../shareable/SocialIcons";
import Button from "./../shareable/Button";
import Hover from "./../shareable/Hover";

const Footer = () => {
  const [translateValue, setTranslateValue] = useState(100);
  const containerRef = useRef(null);

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const handleTranslate = () => {
      if (containerRef.current.getBoundingClientRect().top - windowHeight < 0) {
        setTranslateValue(
          ((containerRef.current.getBoundingClientRect().bottom -
            windowHeight) /
            containerRef.current.getBoundingClientRect().height) *
            200
        );
      }
    };

    document.addEventListener("scroll", handleTranslate);

    return () => document.removeEventListener("scroll", handleTranslate);
  }, []);

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="white-section" ref={containerRef}>
      <div
        className="container-narrow"
        style={{
          transform: `translateY(-${translateValue}px)`,
        }}
      >
        <div className="top">
          <h2>
            Have an idea? <br />
            Let’s build it!
          </h2>
          <a className="button-wrapper" target="_blank" href="http://calendly.com/codecapital">
            <Button type="dark" text="Book a call" />
          </a>
        </div>
        <div className="bottom">
          <div className="line">
            <div className="contact-info">
              <a href="mailto:hello@codecapital.tech">
                <Hover>hello@codecapital.tech</Hover>
              </a>
              <a href="tel:+923137444396">
                <Hover>+92 313 7444 396</Hover>
              </a>
            </div>
            <div onClick={handleScroll} className="back">
              <Hover>Back to top</Hover>
            </div>
          </div>
          <div className="line">
            <div className="copyrights">© All Rights Reserved</div>
            {/*<SocialIcons />*/}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
