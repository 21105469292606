import React, {useEffect, useState} from "react";

import Home from "./pages/Home/Home";
import Projects from "./pages/Projects/Projects";
import {animateElement, handleLoad} from "./scripts/textAnimation";
import Navigation from "./components/navigation/Navigation";
import Footer from "./components/footer/Footer";
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import Contact from "./pages/Contact/Contact";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";

import {Route, Routes, useLocation} from "react-router-dom";

//IMPORT PROJECTS
import Creddapp from "./pages/ProjectPost/Creddapp";
// import Quran from "./pages/ProjectPost/Quran";
// import Barnsley from "./pages/ProjectPost/Barnsley";
// import Hybrid from "./pages/ProjectPost/Hybrid";
// import Crypterium from "./pages/ProjectPost/Crypterium";
// import Execwebsite from "./pages/ProjectPost/Execwebsite";
// import Betero from "./pages/ProjectPost/Betero";
// import Bgfinest from "./pages/ProjectPost/Bgfinest";
// import Brivian from "./pages/ProjectPost/Brivian";
// import Wavelle from "./pages/ProjectPost/Wavelle";
// import Custathread from "./pages/ProjectPost/Custathread";
// import Jetprojectlanding from "./pages/ProjectPost/Jetprojectlanding";
// import Darkfrontierspage from "./pages/ProjectPost/Darkfrontierspage";
// import Dylan from "./pages/ProjectPost/Dylan";
// import Gotoafrica from "./pages/ProjectPost/Gotoafrica";
// import Balmain from "./pages/ProjectPost/Balmain";
// import Mapple from "./pages/ProjectPost/Mapple";
// import Virtualclassroom from "./pages/ProjectPost/Virtualclassroom";
// import Creddlanding from "./pages/ProjectPost/Creddlanding";
// import Viziyaarchitecture from "./pages/ProjectPost/Viziyaarchitecture";
// import Goatagency from "./pages/ProjectPost/Goatagency";

const App = () => {
    const [projects, setProjects] = useState([]);

    const [arr, setArr] = useState([]);

    const location = useLocation();

    useEffect(() => {
        const initLoad = () => {
            fetch(process.env.PUBLIC_URL + "/json/projects.json")
                .then((res) => res.json())
                .then((data) => {
                    setProjects([...data]);

                    const arr = [];

                    for (let i = 0; i < data.length; i++) {
                        const component =
                            data[i].link.slice(0, 1).toUpperCase() + data[i].link.slice(1);

                        const Item = React.lazy(() =>
                            import(`./pages/ProjectPost/${component}`)
                        );

                        arr[i] = <Item/>;
                    }

                    setArr(arr);
                });
        };

        initLoad();
    }, []);

    useEffect(() => {
        const initLoad = () => {
            if (document.readyState === "complete") {
                handleLoad();
            } else {
                setTimeout(() => {
                    initLoad();
                }, 50);
            }
        };

        initLoad();

        window.addEventListener("resize", handleLoad);

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });

        return () => {
            window.removeEventListener("resize", handleLoad);
        };
    }, [location]);

    useEffect(() => {
        window.addEventListener("scroll", animateElement);
        return () => window.removeEventListener("scroll", animateElement);
    }, []);

    // useEffect(() => {
    //   window.history.scrollRestoration = "manual";
    // }, []);

    return (
        <ScrollToTop>
            <Navigation/>
            <Routes>
                <Route path="/" element={<Home projects={projects}/>}/>
                <Route path="/projects" element={<Projects projects={projects}/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/services" element={<Services projects={projects}/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/creddapp" element={<Creddapp/>}/>


                {/*<Route path="/barnsley" element={<Barnsley />} />*/}
                {/*<Route path="/hybrid" element={<Hybrid />} />*/}
                {/*<Route path="/crypterium" element={<Crypterium />} />*/}
                {/*<Route path="/execwebsite" element={<Execwebsite />} />*/}
                {/*<Route path="/betero" element={<Betero />} />*/}
                {/*<Route path="/bgfinest" element={<Bgfinest />} />*/}
                {/*<Route path="/brivian" element={<Brivian />} />*/}
                {/*<Route path="/wavelle" element={<Wavelle />} />*/}
                {/*<Route path="/custathread" element={<Custathread />} />*/}
                {/*<Route path="/jetprojectlanding" element={<Jetprojectlanding />} />*/}
                {/*<Route path="/quran" element={<Quran />} />*/}
                {/*<Route path="/darkfrontierspage" element={<Darkfrontierspage />} />*/}
                {/*<Route path="/dylan" element={<Dylan />} />*/}
                {/*<Route path="/gotoafrica" element={<Gotoafrica />} />*/}
                {/*<Route path="/balmain" element={<Balmain />} />*/}
                {/*<Route path="/mapple" element={<Mapple />} />*/}
                {/*<Route path="/virtualclassroom" element={<Virtualclassroom />} />*/}
                {/*<Route path="/creddlanding" element={<Creddlanding />} />*/}
                {/*<Route path="/viziyaarchitecture" element={<Viziyaarchitecture />} />*/}
                {/*<Route path="/goatagency" element={<Goatagency />} />*/}
            </Routes>

            <Footer/>
        </ScrollToTop>
    );
};

export default App;
