import React from "react";
import Hero from "../../components/hero/Hero";

import locationSerbia from "../../assets/images/location.png";
import locationUSA from "../../assets/images/cheyenne.jpg";

import "./contact.css";
import Info from "./Info";
import Location from "./Location";

const Contact = () => {
  return (
    <div className="content-hero contact-page">
      <Hero title="Lets speak about your project" />
      <Info />
      <div className="section-space container-narrow location-group">

      {/*<Location location="Serbia, Belgrade" adress="Hadzi-Ruvimova 15, Vracar" image={locationSerbia}/>*/}
      {/*<Location location="USA, Wyoming" adress="W 20th St, Cheyenne" image={locationUSA}/>*/}
      </div>
    </div>
  );
};

export default Contact;
