import React from "react";
import Hero from "../../components/hero/Hero";
import Button from "./../../components/shareable/Button";

const HeroHome = () => {
  return (
    <Hero title="We help companies all around the world to grow">
      <div className="buttons animate-element fade-up onload">
        <div className="cta-buttons">
          <Button type="light" text="Our portfolio" href="projects" />
          <Button type="dark" text="Contact us" href="contact" />
        </div>
        {/*<Button type="scroll" href="#show-reel" />*/}
      </div>
    </Hero>
  );
};

export default HeroHome;
