import React, { useState, useEffect } from "react";
import ServicesItem from "./ServicesItem";

const servicesList = [
  {
    category: "ui/ux",
    title: "UI/UX design",
    desc: "We design simple, beautiful and differentiated digital products. A lot goes into that, but if we boil our approach down to one objective. It would be discovering what users want and designing the simplest way for them to get there. We can run this process from start to finish, or act as an extension of your product team.",
    capabilities:
      "Interface design, Usability, Interactions, User experience, Accessibility and etc.",
  },
  {
    category: "development",
    title: "Development",
    desc: "Work with our experts to implement the newest, cutting-edge technologies into your business and kick-start your growth. We focus on the newest technologies and frameworks, trends, data analysis and research to bring you optimal results.",
    capabilities: "Front-End, Back-End, Responsive design, APIs, AI, ML",
  },
  // {
  //   category: "animation",
  //   title: "Illustration and animation",
  //   desc: "Custom graphics present a popular effective way to make a digital product attractive and emotional. Animation is a proven way to breathe life in a digital product or marketing campaign. ",
  //   capabilities:
  //     "Promo videos, Interaction design, Motion graphics, Graphic design and etc.",
  // },
  {
    category: "branding",
    title: "Branding",
    desc: "A brand today is all about how it makes your customers feel. It's not a logo, visual identity, or digital product design, but rather a cohesive system that spans across all mediums and touchpoints. We're a branding agency offering a complete solution from naming and logo design to communications and style guides.",
    capabilities:
      "Brand identity, Branding styleguide, Logo design, Imagery, Positioning and etc. ",
  },
];

const RenderServices = ({ projects }) => {
  const [filteredProjects, setFilteredProjects] = useState([[], [], [], []]);

  useEffect(() => {
    setFilteredProjects((prev) => {
      for (let i = 0; i < servicesList.length; i++) {
        prev[i] = projects.filter(
          (project) => project.service === servicesList[i].category
        );
      }

      return [...prev];
    });
  }, [projects]);

  return (
    <section className="render-services section-space container-narrow">
      {servicesList.map((service, index) => (
        <ServicesItem
          item={service}
          key={index}
          projects={filteredProjects[index]}
        />
      ))}
    </section>
  );
};

export default RenderServices;
