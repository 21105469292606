const separateTextElements = document.querySelectorAll(".separate-text");

// Separate text to line
const separateTextToLine = (element) => {
    let lineArr = [];
    const font = window.getComputedStyle(element).getPropertyValue("font");
    const elementWidth = element.offsetWidth;
    if (elementWidth === 0) return;

    let text = element.dataset.text;
    element.innerHTML = "";

    const getTextWidth = (text) => {
        const canvas =
            getTextWidth.canvas ||
            (getTextWidth.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");
        context.font = font;

        const metrics = context.measureText(text);
        return metrics.width;
    };

    let textWidth = getTextWidth(text);
    const word = text.split("");

    let index = 0;
    let disable = 0;
    while (textWidth > 0) {
        let lineWidth = 0;
        let counter = 0;
        let line = "";
        let spaceIndex;

        while (lineWidth < elementWidth && counter < word.length) {
            if (lineWidth + getTextWidth(word[counter]) > elementWidth) {
                counter--;

                break;
            }
            if (word[counter + 1] === " ") spaceIndex = counter + 1;
            line += word[counter];
            lineWidth = getTextWidth(line);

            counter++;
        }

        if (disable < 1) {
            if (word[counter - 1] !== " " && counter !== word.length) {
                counter = spaceIndex;
            }
        } else {
            disable = 0;
        }
        let singleLine = word.splice(0, counter);

        if (word[0] === " ") word.shift();

        if (singleLine.join("").length === 0) disable++;

        const measureLine = document.createElement("div");
        measureLine.innerHTML = singleLine.join("");

        element.appendChild(measureLine);

        index++;
        textWidth = getTextWidth(word.join(""));
    }

    return lineArr;
};

// Initial separate and animate after load page
export const handleLoad = () => {
    document.fonts.load('1em "Montserrat"').then(() => {
        const separateTextElements = document.querySelectorAll(".separate-text");
        const textAnimationElements = Array.from(
            document.querySelectorAll(".animate-element.onload")
        );

        separateTextElements.forEach((item) => {
            item.style.opacity = 1;
            separateTextToLine(item);
        });

        setTimeout(() => {
            textAnimationElements.forEach((item) => {
                activeAnimation(item);
            });
        }, 0);
    });
};

// Active animation function
const activeAnimation = (item) => {
    item.classList.add("show-element");
};

export const animateElement = () => {
    const textAnimationElements = Array.from(
        document.querySelectorAll(
            ".animate-element:not(.onload):not(.show-element)"
        )
    );

    if (textAnimationElements.length === 0) return;

    const item = textAnimationElements[0];

    if (!item.dataset.onload) {
        let breakElement = item;

        if (item.dataset.parent) {
            breakElement = item.parentElement;
        }

        if (breakElement.getBoundingClientRect().top < window.innerHeight * 1) {
            activeAnimation(item, +item.dataset.timeout);
        }
    }
};

window.addEventListener("resize", () => {
    separateTextElements.forEach((item) => separateTextToLine(item));
});
