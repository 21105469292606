import React, {useState} from "react";
import {Link} from "react-router-dom";
import Svg from "./../../components/shareable/Svg";

const ServicesItem = ({projects, item}) => {
    const [sliderIndex, setSliderIndex] = useState(0);
    const [disable, setDisable] = useState(false);

    const changeSlider = (dir) => {
        setTimeout(() => {
            setTimeout(() => {
                setDisable(false);
            }, 450);
        }, 50);

        if (dir === "next") {
            setSliderIndex((prev) => {
                let index = prev + 1;
                if (index > projects.length - 1) {
                    index = 0;
                }
                return index;
            });
        }

        if (dir === "prev") {
            setSliderIndex((prev) => {
                let index = prev - 1;

                if (index < 0) {
                    index = projects.length - 1;
                }

                return index;
            });
        }
    };

    return (<div className="services-item section-space animate-element fade-up">
        <div className="slider ">
            <div className="images">
                {projects.map((project, index) => (<img
                    src={process.env.PUBLIC_URL + `/projectImage/${project?.image}.jpg`}
                    key={index}
                    alt=""
                    style={{
                        // zIndex: sliderIndex === index ? 2 : 1,
                        opacity: sliderIndex !== index ? 0 : 1, transition: "1000ms",
                    }}
                />))}
            </div>
            <div className="controls">
                <div className="case">
                    <span className="opacity">Recent Case </span>
                    <Link to={`/${projects[sliderIndex]?.link}`}>
                        {projects[sliderIndex]?.name}
                    </Link>
                </div>
                <div className="arrows">
                    <div
                        className="left arrow"
                        onClick={() => {
                            if (!disable) {
                                changeSlider("prev");
                                setDisable(true);
                            }
                        }}
                    >
                        <Svg name="sliderArrow"/>
                    </div>
                    <div
                        className="right arrow"
                        onClick={() => {
                            if (!disable) {
                                changeSlider("next");
                                setDisable(true);
                            }
                        }}
                    >
                        <Svg name="sliderArrow"/>
                    </div>
                </div>
            </div>
        </div>
        <div className="info">
            <div>
                <h2 className="">{item?.title}</h2>
                <p className="description">{item?.desc}</p>
            </div>

            <div className="technology">
                <div className="title">
                    <p className="">{`IN-HOUSE CAPABILITIES`}</p>
                </div>
                <div className="list">
                    <p className="">{item?.capabilities}</p>
                </div>
            </div>
        </div>
    </div>);
};

export default ServicesItem;
