import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Creddapp = () => {
  const imageFolder = "credd";
  return (
    <div className="project-template">
      <TemplateHero
        imageFolder={imageFolder}
        title="Dream tracking app design"
        imageName="creddlanding"
        subtitle="Credd"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Credd is a mobile application designed to help users track their dreams and analyze them. We started by researching the existing dream tracking apps and analyzing user reviews to determine the most important features. We then created the user interface with intuitive navigation and a simplified experience. We designed the app to make it easy to track your dreams with just a few taps. To ensure accuracy, we created a database of dream symbols and their meanings. This database provides users with personalized insights when they record their dreams.`,
        ]}
      />

      <ImageGroup columns={1} images={["credd-1"]} imageFolder={imageFolder} />

      <InfoSection
        title="Unique experience"
        paragraphs={[
          `The app was designed with a focus on simplicity, allowing users to quickly and easily create dream entries and access them from any device. The app features an intuitive user interface that guides users through the dream entry process. The design was created to be easy to follow and visually pleasing, utilizing a flat design with a mix of vibrant and muted colors.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["credd-2", "credd-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Design solutions"
        paragraphs={[
          `Credd provides users with an organized dashboard that allows them to easily view dream data over time. This feature allows users to easily track patterns in their dreams and gain insights about their dream life. Additionally, Credd offers a variety of tools to help users interpret their dreams. This includes dream dictionaries, dream analysis tools, and dream journaling tools.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["credd-4", "credd-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Sharing"
        paragraphs={[
          `Credd also allows users to share their dreams with others. This feature is great for users to receive feedback and insights from their peers. It also allows users to connect with other users who are interested in dream tracking. Overall, Credd is a great way to track and monitor dreams over time. It provides users with an easy-to-use interface and a variety of tools to help them better understand their dreams.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["credd-6", "credd-7"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Problem solving"
        paragraphs={[
          `To ensure that users have a positive experience when using the app, we applied a user-centered design approach to solve various UI/UX problems. First, we identified the user needs and goals by conducting interviews and surveys to understand how users interact with dream tracking apps and what they expect from such an app. We also studied competitors’ apps and their features to get an idea of what users might find useful. Second, we created user personas and scenarios to better understand the needs of our target audience and how they might use the app. This enabled us to design a user experience that takes into account the user’s wants and needs.`,
        ]}
      />

      <ImageGroup columns={1} images={["credd-8"]} imageFolder={imageFolder} />

      <InfoSection
        title="Clients feedback"
        paragraphs={[
          `“We like organization and web design skills. They did all to help us with our tight deadline. We had a great collaboration, working with Handmade was a real pleasure. They do excellent work in all areas of the project, but it is specifically their design work that puts them head and shoulders above the rest.”`,
        ]}
        name="Alice Cooper"
        position="CEO"
      />
    </div>
  );
};

export default Creddapp;
