import React from "react";

const ImageGroup = ({ columns, images, imageFolder, onlyDesktop }) => {
  return (
    <div
      className="image-group container section-space animate-element fade-up"
      style={{ gridTemplateColumns: `repeat(${columns},1fr)` }}
    >
      {images.map((image, index) => (
        <picture key={index}>
          {!onlyDesktop && (
            <>
              <source
                media="(max-width: 599px)"
                srcSet={
                  process.env.PUBLIC_URL +
                  `/projectImage/${imageFolder}/${image}-mob.jpg`
                }
              />
              <source
                media="(min-width: 600px)"
                srcSet={
                  process.env.PUBLIC_URL +
                  `/projectImage/${imageFolder}/${image}.jpg`
                }
              />
            </>
          )}
          <img
            src={
              process.env.PUBLIC_URL +
              `/projectImage/${imageFolder}/${image}.jpg`
            }
            alt=""
          />
        </picture>
      ))}
    </div>
  );
};

export default ImageGroup;
