import React, { useState, useEffect, useRef } from "react";

import "./navigation.css";

import Logo from "../../assets/logo/logo.svg";
import LogoBlack from "../../assets/logo/logo-black.svg";

import MenuItem from "./MenuItem";
import SocialIcons from "../shareable/SocialIcons";

import { useLocation, Link } from "react-router-dom";
import Hover from "../shareable/Hover";

const navItems = [
  { text: "Home", link: "/" },
  { text: "Projects", link: "/projects" },
  { text: "About us", link: "/about" },
  { text: "Services", link: "/services" },
  { text: "Contact", link: "/contact" },

];

const Navigation = () => {
  const [navOpen, setNavOpen] = useState(false);

  const buttonRef = useRef(null);

  const location = useLocation();

  const handleClick = () => {
    setNavOpen((prev) => !prev);
  };

  useEffect(() => {
    setNavOpen(false);
  }, [location]);

  const changeButtonColor = () => {
    const whiteSections = document.querySelectorAll(".white-section");

    const btn = buttonRef.current;
    const lines = btn.querySelectorAll(".line");

    for (let i = 0; i < whiteSections.length; i++) {
      if (
        whiteSections[i].getBoundingClientRect().top <=
          btn.getBoundingClientRect().top &&
        whiteSections[i].getBoundingClientRect().bottom >=
          btn.getBoundingClientRect().top
      ) {
        for (let j = 0; j < lines.length; j++) {
          lines[j].style.backgroundColor = "black";
        }

        break;
      } else {
        for (let j = 0; j < lines.length; j++) {
          lines[j].style.backgroundColor = "white";
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", changeButtonColor);

    return () => document.removeEventListener("scroll", changeButtonColor);
  });

  return (
    <>
      <nav className="container animate-element onload">
        <Link to="/">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
        </Link>
        <div
          className={`button ${navOpen ? "active-button" : ""}`}
          onClick={handleClick}
        >
          <p>menu</p>
          <div ref={buttonRef} className="hamburger-bar">
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </nav>

      <div
        style={{ opacity: navOpen ? 1 : 0 }}
        className={`navigation-menu ${navOpen ? "open" : ""}`}
      >
        <div className="top">
          <img src={LogoBlack} alt="" />
        </div>
        <div className="nav-list">
          <ul>
            {navItems.map((item, index) => (
              <MenuItem text={item.text} link={item.link} key={index} />
            ))}
            <a href={"/blog"} target={"_blank"}>
              <li>
                <Hover>{"Blog"}</Hover>
              </li>
            </a>
          </ul>
        </div>
        {/*/*This will be removed if there is socials*/} <div> </div>

        {/*<SocialIcons />*/}
      </div>
    </>
  );
};

export default Navigation;
