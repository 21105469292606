import React, { useState, useRef, useEffect } from "react";
import Button from "../../components/shareable/Button";

import { Link } from "react-router-dom";

const Projects = ({ awardRef, serviceRef, projects }) => {
  const [printProjects, setPrintProjects] = useState([]);
  const [activeProject, setActiveProject] = useState(false);
  const [projectIndex, setProjectIndex] = useState(0);
  const [projectNeutral, setProjectNeutral] = useState(true);
  const [showLearnMore, setShowLearnMore] = useState(false);

  const [btnPos, setBtnPos] = useState([0, 0]);

  const projectRef = useRef(null);

  useEffect(() => {
    const scrollFunction = (event) => {
      console.log("page is fully loaded");

      document.removeEventListener("scroll", scrollFunction);

      const homeProjects = projects.filter((project) => project.home);
      setPrintProjects([...homeProjects]);

      homeProjects.forEach((item) => {
        const img = document.createElement("img");
        img.src = process.env.PUBLIC_URL + `/projectImage/${item.image}.jpg`;
      });
    };

    document.addEventListener("scroll", scrollFunction);

    return () => document.removeEventListener("scroll", scrollFunction);
  }, [projects]);

  useEffect(() => {
    const projectIntoView = () => {
      if (
        awardRef.current.getBoundingClientRect().bottom -
          window.innerHeight * 0.2 <
          0 &&
        serviceRef.current.getBoundingClientRect().top - window.innerHeight > 0
      ) {
        awardRef.current.classList.add("hide");
        serviceRef.current.classList.add("hide");

        setActiveProject(true);
      } else {
        setActiveProject(false);
        setShowLearnMore(false);

        if (awardRef.current.classList.contains("hide")) {
          awardRef.current.classList.remove("hide");
        } else if (serviceRef.current.classList.contains("hide")) {
          serviceRef.current.classList.remove("hide");
        }
      }

      changeItemOnScroll();
    };

    const changeItemOnScroll = () => {
      const projectChildren = Array.from(projectRef.current.children);
      const firstItem = projectChildren[0];

      if (window.innerWidth < 900) {
        if (
          projectRef.current.getBoundingClientRect().top > 0 ||
          serviceRef.current.getBoundingClientRect().top < window.innerHeight
        ) {
          return;
        }

        let points = document.elementsFromPoint(
          window.innerWidth / 2,
          window.innerHeight / 2 - firstItem.getBoundingClientRect().height / 2
        );

        for (let i = 0; i < points.length; i++) {
          if (
            points[i].classList.contains("wrapper") &&
            !points[i].children[0].classList.contains("active")
          ) {
            setProjectIndex(+points[i].dataset.id);
            setProjectNeutral(false);

            break;
          }
        }

        let newScrollPos = window.scrollY;

        let activeElement;

        setTimeout(() => {
          if (newScrollPos === window.scrollY) {
            for (let i = 0; i < projectChildren.length; i++) {
              if (projectChildren[i].children[0].classList.contains("active")) {
                activeElement = projectChildren[i].children[0];
                break;
              }
            }
            window.scrollBy(
              0,
              activeElement.getBoundingClientRect().top +
                activeElement.getBoundingClientRect().height / 2 -
                window.innerHeight / 2
            );
          }
        }, 500);
      } else {
        projectChildren.forEach((element, index) => {
          if (
            +element.getBoundingClientRect().top.toFixed() +
              element.getBoundingClientRect().height / 2 <
              btnPos[1] &&
            +element.getBoundingClientRect().bottom.toFixed() +
              element.getBoundingClientRect().height / 2 >
              btnPos[1]
          ) {
            setProjectIndex(index);
            setProjectNeutral(false);
          }
        });
      }
    };

    document.addEventListener("scroll", projectIntoView);

    return () => document.removeEventListener("scroll", projectIntoView);
  }, [btnPos]);

  useEffect(() => {
    if (window.innerWidth < 900) return;

    const moveBtn = (moveEvent) => {
      setBtnPos([moveEvent.clientX + 12, moveEvent.clientY + 17]);
    };

    document.addEventListener("mousemove", moveBtn);

    return () => document.removeEventListener("mousemove", moveBtn);
  }, []);

  const handleEnter = (index) => {
    if (window.innerWidth < 900) return;

    setProjectIndex(index);
    setProjectNeutral(false);
    setShowLearnMore(true);
  };

  const handleLeave = () => {
    if (window.innerWidth < 900) return;

    setProjectNeutral(true);
    setShowLearnMore(false);
  };

  return (
    <>
      <div
        id="project-media"
        style={{
          opacity: activeProject ? 1 : 0,
          backgroundImage: `url(${
            process.env.PUBLIC_URL +
            `/projectImage/${printProjects[projectIndex]?.image}.jpg`
          })`,
        }}
        className={`project-media ${activeProject ? "show" : ""}`}
      ></div>

      <div
        id="project-details"
        style={{ opacity: activeProject ? 1 : 0 }}
        className={`project-details ${activeProject ? "show" : ""}`}
      >
        <div className="info">
          <p>Recent case</p>
          <div id="case-name" className="case-name">
            {printProjects[projectIndex]?.case}
          </div>
        </div>
        <Button type="scroll" href="#services" />
        <div className="button-light">
          <Link to="projects">
            <p>All projects</p>
          </Link>
        </div>
      </div>

      <div
        id="learn-more-btn"
        className={`learn-more-btn ${showLearnMore ? "show" : ""}`}
        style={{ left: btnPos[0], top: btnPos[1] }}
      >
        Learn more
      </div>

      <section
        id="projects"
        className={`projects  ${activeProject ? "" : "hide"}`}
        ref={projectRef}
      >
        {printProjects.map((project, index) => (
          <Link
            to={project.link}
            key={index}
            className="wrapper"
            data-id={index}
            onMouseEnter={() => handleEnter(index)}
            onMouseLeave={handleLeave}
            target={"_blank"}
          >
            <div
              className={`title ${
                index === projectIndex && !projectNeutral ? "active" : ""
              } ${projectNeutral ? "neutral" : ""}`}
            >
              {project.name}
            </div>
          </Link>
        ))}
      </section>
    </>
  );
};

export default Projects;
