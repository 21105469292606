import React from "react";

// import Trophy from "../../assets/video/trophy.mp4";
import DescriptionSection from "../../components/shareable/DescriptionSection";

const awardList = [
    {
        name: "Awwwards",
        category: "Honorable",
        count: 6,
    },
    {
        name: "Behance",
        category: "UI Featured",
        count: 1,
    },
    {
        name: "CSSDA",
        category: "Special kudos",
        count: 5,
    },
    {
        name: "CSSDA",
        category: "Innovation",
        count: 5,
    },
    {
        name: "CSSDA",
        category: "UI award",
        count: 5,
    },
    {
        name: "CSSDA",
        category: "UX award",
        count: 5,
    },
    {
        name: "Css Winner",
        category: "Star award",
        count: 2,
    },
];

const icons = ["react", "javascript", "html", "css", "angular", "plus"];

const texts = [
    [
        `We create, products, brands, apps & websites for companies all around the world. To us, world-class digital products take more than intelligent code or creative design. It requires true partnership between the ideators and the innovators. Our collaborations are built on a bedrock of transparency, communication, expert project management, and attention to detail.`,
    ],
    [
        `From the moment our company was founded, we have helped our clients find exceptional solutions for their businesses , creating memorable brands and digital products. We offer comprehensive services to aid you in the entire product development process.`,
    ],
];

const Award = ({sectionRef}) => {
    const description = (
        <>
            <span></span>
            <span>
        We provide wide range <br/> of services
      </span>
        </>
    );

    return (
        <section
            ref={sectionRef}
            id="award"
            className="awards container-narrow"
        >

            <DescriptionSection
                icons={icons}
                texts={texts}
                title="Offering best services"
                description={description}
            />


            {/*<div className="counter animate-element fade-up" data-parent="true">*/}
            {/*  20+*/}
            {/*</div>*/}

            {/*<h2 className="animate-element fade-up" data-parent="true">*/}
            {/*  Awards for <br/>*/}
            {/*  digital innovation*/}
            {/*</h2>*/}

            {/*<div className="trophy">*/}
            {/*  <video autoPlay muted playsInline loop>*/}
            {/*    /!*<source src={Trophy} type="video/mp4" />*!/*/}
            {/*    Your browser does not support the video tag.*/}
            {/*  </video>*/}
            {/*</div>*/}
            {/*<div className="award-list">*/}
            {/*  <ul>*/}
            {/*    {awardList.map((item, index) => (<AwardItem item={item} key={index}/>))}*/}
            {/*    <li className="more animate-element fade-up" data-parent="true">*/}
            {/*      And many more...*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
        </section>
    );
};

export default Award;
