import React, { useRef, useState, useEffect } from "react";

const TemplateHero = ({ imageFolder, imageName, title, subtitle }) => {
  return (
    <>
      <section
        className="template-hero"
        style={{
          height: window.innerHeight,
          backgroundImage: `url(${
            process.env.PUBLIC_URL +
            `/projectImage/${imageFolder}/${imageName}.jpg`
          })`,
        }}
      >
        <div className="container-narrow animate-element onload">
          <div className="subtitle">
            <span className="line">—</span>
            {subtitle}
          </div>
          <h1 className="" data-text={title}>
            {title}
          </h1>
        </div>
      </section>
    </>
  );
};

export default TemplateHero;
